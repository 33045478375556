import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Traec from "traec";
import Octicon from "react-octicon";
import { BSModal } from "traec-react/utils/bootstrap";
import ProgressBar from "traec-react/utils/bootstrap/progressBar";
import WizardModal from "traec-react/utils/bootstrap/wizardModal";
import { alertSuccess } from "traec-react/utils/sweetalert";
import Swal from "sweetalert2";

const getCompanyMatches = (companyData, setMatchingCompanies) => {
  let fetch = new Traec.Fetch("company_dispatch", "post", {});
  fetch.updateFetchParams({
    body: {
      type: "GET_MATCHING",
      payload: companyData
    },
    postSuccessHook: data => {
      //console.log("Handling matching company response", data);
      setMatchingCompanies(Traec.Im.fromJS(data.payload));
    }
  });

  fetch.dispatch();
};

const setupNewCompany = companyData => {
  let fetch = new Traec.Fetch("company_dispatch", "post", {});
  fetch.updateFetchParams({
    body: {
      type: "SETUP_NEW_COMPANY",
      payload: companyData
    }
  });

  Swal.queue([
    {
      title: "Creating Account",
      text: "We are setting up your account. This will take a few seconds then the page will refresh.",
      showConfirmButton: true,
      showCancelButton: false,
      showLoaderOnConfirm: true,
      backdrop: false,
      iconType: "success",
      type: "success",
      preConfirm: () => {
        return fetch
          .rawFetch({ updateBody: true })
          .then(response => {
            if (response.status == 500) {
              throw new Error("Server error 500");
            }
            return response.json();
          })
          .then(data => {
            if (data.status !== "success") {
              throw new Error("Error setting up account");
            }
            setTimeout(() => location.reload(), 5000);
            Swal.insertQueueStep({
              showCancelButton: false,
              showConfirmButton: false,
              title: "Success",
              html: `Account created.  This page will now refresh.`,
              iconType: "success",
              type: "success",
              preConfirm: () => location.reload(),
              preDeny: () => location.reload()
            });
          })
          .catch(err => {
            console.log(err);
            Swal.insertQueueStep({
              type: "error",
              title: "Error",
              iconType: "error",
              text: `There was an error setting up your account.  Contact support if the problem persists.`
            });
          });
      }
    }
  ]);
};

const requestJoinCompany = company => {
  let companyId = company.get("uid");
  let companyName = company.get("name");
  let fetch = new Traec.Fetch("company_invite_all", "post", { companyId });
  fetch.updateFetchParams({
    postSuccessHook: data => {
      //console.log("Handling company join request response", data);
      let success = !data.errors;
      alertSuccess({
        iconType: success ? "success" : "error",
        text: success
          ? `Your request to join ${companyName} as been submitted.  A colleague of yours must approve this request before you can access the report for this company`
          : `There was an error requesting access to ${companyName}.  Please contact support if this persists.`,
        onConfirm: () => {
          location.reload();
        }
      });
    }
  });

  fetch.dispatch();
};

function GoBackButton({ setMatchingCompanies }) {
  return (
    <React.Fragment>
      <button className="btn btn-danger float-left" onClick={e => setMatchingCompanies(null)}>
        Go back
      </button>
      <div style={{ clear: "both" }} />
    </React.Fragment>
  );
}

function ProceedToCreateButton({ setMatchingCompanies }) {
  return (
    <React.Fragment>
      <button className="btn btn-outline-secondary float-left" onClick={e => setMatchingCompanies(Traec.Im.List())}>
        My company is <u>not</u> listed
      </button>
      <div style={{ clear: "both" }} />
    </React.Fragment>
  );
}

function RequestJoinCompanyButton({ matchingCompanies, selected }) {
  return (
    <button className="btn btn-primary float-right" onClick={e => requestJoinCompany(matchingCompanies.get(selected))}>
      Join selected company
    </button>
  );
}

function SetCompanyName({ companyName, setCompanyName }) {
  let [name, setName] = useState("");
  return (
    <React.Fragment>
      <p>Enter a company name:</p>
      <div className="form-group">
        <input id="set_company_name" className="form-control" value={name} onChange={e => setName(e.target.value)} />
      </div>
      <button className="btn btn-primary" onClick={e => setCompanyName(name)}>
        Done
      </button>
    </React.Fragment>
  );
}

function SetupNewCompany(props) {
  let { companyName, setCompanyName, companyDomain } = props;
  let [freq, setFreq] = useState("annually");

  if (!companyName) {
    return <SetCompanyName {...props} />;
  }

  // let options = ["Monthly", "Quarterly", "Annually"].map((f, i) => (
  // let options = ["Annually"].map((f, i) => (
  //   <option key={i} value={f.toLowerCase()}>
  //     {f}
  //   </option>
  // ));

  return (
    <React.Fragment>
      <div className="jumbotron jumbotron-fluid text-white " style={{ backgroundColor: "#337ab7" }}>
        <div className="container">
          <h4 className="display-4 pb-2">Almost there...</h4>
          <p className="lead pt-3">
            You are creating an account for {companyName} (domain: {companyDomain}) click continue below to complete
            your account registration.
          </p>
        </div>
      </div>
      <div className="container shadow py-3 rounded">
        {/* <div className="form-group">
          <div className="mb-3 text-muted">
            Please, be aware that the reporting frequency cannot be modified in the future, so please ensure that you
            are selecting the option that works best for your organisation or that meets your client's requirements.
          </div>
          <label>Choose a reporting frequency:</label>
          <select
            className="form-control form-control-sm"
            defaultValue={freq}
            onChange={e => {
              setFreq(e.target.value);
            }}
          >
            {options}
          </select>
        </div>
        <br /> */}
        <button
          className="btn btn-primary float-right"
          onClick={e =>
            setupNewCompany({
              name: companyName,
              domain: companyDomain,
              frequency: freq
            })
          }
        >
          Continue
        </button>
        <GoBackButton {...props} />
      </div>
      <div className="container my-5">
        <ProgressBar title={"Profile setup"} percentage={"85"} />
      </div>
    </React.Fragment>
  );
}

function MultipleMatchPreamble() {
  return (
    <React.Fragment>
      <p>Please select your company if it is listed.</p>{" "}
      <p className="text-muted">
        The following companies are already registered on the system. We will send a request to your colleagues to
        verify you and accept you on to the system.
      </p>
    </React.Fragment>
  );
}

function ExactMatchPreamble() {
  return (
    <React.Fragment>
      <p>
        There is a company already on the system that exactly matches the name or domain given. This might be due to a
        colleague that has already registered and setup on the system.
      </p>
      <p>
        If this is your company then press the submit button below. We will send a request to your colleagues to verify
        you and accept you on to the system.
      </p>
      <p>
        If this is not your company, please contact{" "}
        <a className="text-primary" href="mailto: info@sustainabilitytool.com" target="_blank">
          info@sustainabilitytool.com
        </a>
        or select the Go back button.
      </p>
    </React.Fragment>
  );
}

function SelectMatch(props) {
  let { companyName, companyDomain, matchingCompanies } = props;
  if (!matchingCompanies) {
    return null;
  }

  let [selected, setSelected] = useState(
    matchingCompanies.findIndex(i => i.get("name") == companyName || i.get("domain") == companyDomain)
  );
  //let [exactMatch, setExactMatch] = useState(selected >= 0);
  let [exactMatch, setExactMatch] = useState(false);

  let rows = matchingCompanies
    .filter((match, i) => (exactMatch ? i == selected : true))
    .map((match, i) => (
      <div key={i} className="form-check mt-1 mb-1">
        <input
          className="form-check-input"
          type="checkbox"
          checked={i == selected}
          id={match.get("uid")}
          onChange={e => {
            if (!exactMatch) {
              setSelected(i == selected ? -1 : i);
            }
          }}
        />
        <label className="form-check-label" htmlFor={match.get("uid")}>
          {match.get("name")}
        </label>
      </div>
    ));

  return (
    <React.Fragment>
      <div className="jumbotron jumbotron-fluid text-white" style={{ backgroundColor: "#337ab7" }}>
        <div className="container">
          <h4 className="display-4 mb-3">Confirm your company</h4>
        </div>
      </div>
      <div className="container shadow py-3 rounded">
        {exactMatch ? <ExactMatchPreamble /> : <MultipleMatchPreamble />}
        <hr />
        {rows}
        <hr />
        {selected > -1 ? <RequestJoinCompanyButton matchingCompanies={matchingCompanies} selected={selected} /> : null}
        {!exactMatch ? <ProceedToCreateButton {...props} /> : <GoBackButton {...props} />}
        <div style={{ clear: "both" }} />
      </div>
      <div className="container my-5">
        <ProgressBar title={"Profile setup"} percentage={"65"} />
      </div>
    </React.Fragment>
  );
}

function SubmitMatchRequest(props) {
  let { companyName, setCompanyName, companyDomain, setCompanyDomain, setMatchingCompanies, tenantMeta } = props;

  let [domainHasError, setDomainHasError] = useState(false);
  let [domainErrorMessage, setDomainErrorMessage] = useState("");

  const validateDomain = text => {
    const re = /[!@#$%^&*()_+\=\[\]{};±'`:"\\|,<>\/?£]+/;
    if (!text.includes(".")) {
      setDomainHasError(true);
      setDomainErrorMessage("Website entered has no dot (.com/.co.uk etc)");
      return;
    }
    if (text.match(re)) {
      setDomainHasError(true);
      setDomainErrorMessage("Website entered has a symbol or special character (@,£,%,!)");
      return;
    }
    if (text.includes(" ")) {
      setDomainHasError(true);
      setDomainErrorMessage("Website entered has a space");
      return;
    }
    setDomainHasError(false);
    setDomainErrorMessage("");
  };

  const _setCompanyDomain = text => {
    text = text
      .trim()
      .replace("http://", "")
      .replace("https://", "");
    if (text.startsWith("www.")) {
      text = text.substring(4);
    }
    validateDomain(text);
    setCompanyDomain(text);
  };

  //console.log("Rendering the welcome page", tenantMeta);
  let setupTitle = tenantMeta?.get("setup_title") || "Welcome to the Carbon Calculator";

  return (
    <React.Fragment>
      <div className="jumbotron jumbotron-fluid text-white " style={{ backgroundColor: "#337ab7" }}>
        <div className="container">
          <h3 className="display-4 pb-2">{setupTitle}</h3>
          <p className="lead pt-3">Please complete your profile setup</p>
        </div>
      </div>

      <div className="container shadow py-3 rounded">
        <br />
        <div className="form-group">
          <label htmlFor="setup_company_name">Company name</label>
          <input
            id="setup_company_name"
            className="form-control"
            value={companyName}
            onChange={e => setCompanyName(e.target.value)}
          />
        </div>
        <div className="form-group mt-2">
          <label htmlFor="setup_company_domain" className="mb-0">
            Company domain
          </label>
          <small id="domainHelp" class="form-text text-muted mt-0">
            Please enter your company website, removing the prefix (e.g. sustainabilitytool.com instead of
            https://www.sustainabilitytool.com)
          </small>
          <input
            id="setup_company_domain"
            className={`form-control ${domainHasError ? "is-invalid" : ""}`}
            value={companyDomain}
            onChange={e => _setCompanyDomain(e.target.value)}
          />
          {domainHasError ? (
            <div id="invalidDomain" className="invalid-feedback">
              {domainErrorMessage}
            </div>
          ) : null}
        </div>
        <div className="row justify-content-end pr-3">
          <button
            className="btn btn-primary rounded"
            disabled={domainHasError}
            onClick={e => getCompanyMatches({ name: companyName, domain: companyDomain }, setMatchingCompanies)}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="container my-5">
        <ProgressBar title={"Profile setup"} percentage={"35"} />
      </div>
    </React.Fragment>
  );
}

const getUserEmailDomain = user => {
  let domain = user.get("email").split("@")[1] || "";
  return domain;
};

function UserSetup(props) {
  let { user } = props;

  let [companyName, setCompanyName] = useState(user?.getInPath("meta_json.company_name") || "");
  let [companyDomain, setCompanyDomain] = useState(getUserEmailDomain(user));
  let [matchingCompanies, setMatchingCompanies] = useState(null);

  let CurrentComponent = matchingCompanies
    ? matchingCompanies.size
      ? SelectMatch
      : SetupNewCompany
    : SubmitMatchRequest;

  return (
    <CurrentComponent
      {...props}
      companyName={companyName}
      setCompanyName={setCompanyName}
      companyDomain={companyDomain}
      setCompanyDomain={setCompanyDomain}
      matchingCompanies={matchingCompanies}
      setMatchingCompanies={setMatchingCompanies}
    />
  );
}

function UserSetupModal(props) {
  let { user, fetchedAll, hasAnything } = props;
  if (!user) {
    return null;
  }

  let modalId = "user_setup";

  let [state, setState] = useState({});
  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      state,
      setState,
      requiredFetches: [
        new Traec.Fetch("company", "list"),
        new Traec.Fetch("company_invite_all", "list"),
        new Traec.Fetch("project", "list"),
        new Traec.Fetch("project_invite_all", "list")
      ]
    });
  });

  if (fetchedAll && !hasAnything) {
    //$(`#${modalId}`).modal("show"); Add Redirect (Navigate in React Router) below to a new page
    $(`#${modalId}`).modal({ backdrop: "static", keyboard: false });
  }

  return <WizardModal hideClose={true} id={modalId} title={""} body={<UserSetup {...props} />} />;
}

const mapStateToProps = (state, ownProps) => {
  let user = state.getInPath("auth.user");

  let companies = state.getInPath("entities.companies.byId");
  let company_invites = state.getInPath("entities.companyInvites.byId");
  let projects = state.getInPath("entities.projects.byId");
  let project_invites = state.getInPath("entities.projectInvites.byId");

  let items = [companies, company_invites, projects, project_invites];
  let fetchedAll = items.every(i => i != undefined);
  let hasAnything = items.reduce((a, i) => a + (i?.size || 0), 0) > 0;

  let tenantMeta = state.getInPath("entities.tenant.meta_json");

  return {
    user,
    companies,
    company_invites,
    projects,
    project_invites,
    fetchedAll,
    hasAnything,
    tenantMeta
  };
};

export default connect(mapStateToProps)(UserSetupModal);
